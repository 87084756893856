import React, { useEffect, useState } from 'react'
import { AuthenticationLayout as Layout } from 'presentation/components/AuthenticationLayout'
import { useQueryParam, StringParam } from 'use-query-params'
import { useTranslation } from 'react-i18next'
import { authorizeConfirmApproval } from 'scripts/authorizeConfirmApproval'
import { cookies } from 'scripts/cookies'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { postUserResendVerifySMS } from 'api/postUserResendVerifySMS'
import { getSubdomain } from 'scripts/getSubdomainForHost'
import { getResendSettingBySubdomain } from 'api/getResendSettingBySubdomain'
import { postUserResendVerifyEmail } from 'api/postUserResendVerifyEmail'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { Mail, Sms } from '@mui/icons-material'
import { useIpAddress } from 'api/useIpAddress'

export const Verify = () => {
  const [openSnackbar] = useSnackbar()
  const [{ ipAddress }] = useIpAddress()
  const [lockedDeviceUuid] = useState(cookies.get('_locked_device_uuid'))
  const [lockedEventId] = useState(cookies.get('_locked_event_id'))
  const [canUseSmsResend, setCanUseSmsResend] = useState(false)
  const [canUseEmailResend, setCanUseEmailResend] = useState(false)
  const [isSaml] = useQueryParam('saml', StringParam)
  const [appClientKeyQueryParam] = useQueryParam('app_client_key', StringParam)
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    ;(async () => {
      const subdomain = await getSubdomain(window.location.host)
      const response = await getResendSettingBySubdomain(subdomain)
      setCanUseSmsResend(
        response.data.two_factor_resend_setting.enable_sms_resend,
      )
      setCanUseEmailResend(
        response.data.two_factor_resend_setting.enable_email_resend,
      )
    })()
  }, [])

  useEffect(() => {
    const params = {
      locked_event_id: lockedEventId,
      locked_device_uuid: lockedDeviceUuid,
      is_saml: isSaml === 'true',
    }
    const handle = window.setInterval(() => {
      ;(async () => {
        await authorizeConfirmApproval({
          handle,
          params,
          appClientKey: appClientKeyQueryParam,
        })
      })()
    }, 5000)
    setTimeout(function () {
      clearInterval(handle)
    }, 300000) // 5分で解除
    return () => clearInterval(handle)
  }, [])

  const handleClick = async () => {
    const params = {
      locked_event_id: lockedEventId,
      locked_device_uuid: lockedDeviceUuid,
      is_saml: isSaml === 'true',
    }
    const { result } = await authorizeConfirmApproval({
      params,
      appClientKey: appClientKeyQueryParam,
    })
    if (result === 'error') {
      openSnackbar(
        '本人確認が完了していません。２段階認証を完了してください',
        'identityVerificationNotCompleted',
      )
    }
  }
  const handleClickSMS = async () => {
    const subdomain = getSubdomain(window.location.host)
    if (!lockedEventId || !lockedDeviceUuid) {
      openSnackbar('LOCKEDサポート担当までご連絡ください。')
      return
    }

    const params = {
      enduser_event_self_id: lockedEventId,
      enduser_device_uuid: lockedDeviceUuid,
      subdomain,
      user_ip: ipAddress,
    }
    const res = await postUserResendVerifySMS(params)
    if (res.status === 200) {
      openSnackbar('SMSを送信しました', 'sendVerificationSMS')
    } else if (res.data.reason === 'too many resend') {
      openSnackbar('確認コードの送信回数が上限に達しました', 'tooManyResend')
    } else if (res.data.reason === 'invalid phone_number') {
      openSnackbar('電話番号の形式が正しくありません', 'invalidPhoneNumber')
    } else if (res.data.reason === 'two_factor_auth_setting is not active') {
      openSnackbar(
        'あなたの組織で二段階認証が有効になっていません',
        'twoFactorAuthSettingIsNotActive',
      )
    } else if (res.data.reason === 'invailid two factor auth setting') {
      openSnackbar(
        'あなたの組織ではこの方法は使用できません',
        'invailidTwoFactorAuthSetting',
      )
    } else {
      openSnackbar(
        '確認コードの送信に失敗しました',
        'sendVerificationCodeFailed',
      )
    }
    handleClose()
  }

  const handleClickEmail = async () => {
    const subdomain = getSubdomain(window.location.host)
    if (!lockedEventId || !lockedDeviceUuid) {
      openSnackbar('LOCKEDサポート担当までご連絡ください。')
      return
    }

    const params = {
      enduser_event_self_id: lockedEventId,
      enduser_device_uuid: lockedDeviceUuid,
      subdomain,
      user_ip: ipAddress,
    }
    const res = await postUserResendVerifyEmail(params)
    if (res.status === 200) {
      openSnackbar('メールを送信しました', 'sendVerificationEmail')
    } else if (res.data.reason === 'too many resend') {
      openSnackbar('確認コードの送信回数が上限に達しました', 'tooManyResend')
    } else if (res.data.reason === 'email is blank') {
      openSnackbar('メールアドレスが登録されていません', 'emailIsBlank')
    } else if (res.data.reason === 'two_factor_auth_setting is not active') {
      openSnackbar(
        'あなたの組織で二段階認証が有効になっていません',
        'twoFactorAuthSettingIsNotActive',
      )
    } else if (res.data.reason === 'invailid two factor auth setting') {
      openSnackbar(
        'あなたの組織ではこの方法は使用できません',
        'invailidTwoFactorAuthSetting',
      )
    } else {
      openSnackbar(
        '確認コードの送信に失敗しました',
        'sendVerificationCodeFailed',
      )
    }
    handleClose()
  }

  return (
    <Layout dataTest="loadedLayout">
      <p className="text-xl">{t('ご本人確認のお願い')}</p>
      <div className="w-full px-6 text-gray-700">
        <p className="mt-3">
          {t(
            'お客様ご本人によるアクセスであることを確認するために、二段階認証を要求しました。',
          )}{' '}
        </p>
        <p>
          {t(
            'ログインを完了するにはメールアドレスやSMSメッセージをご確認ください。',
          )}{' '}
        </p>
        {(canUseSmsResend || canUseEmailResend) && (
          <p className="text-sm mx-16 mt-16 mb-12">
            {t('2段階認証を受信できない場合は')}
            <button
              id="basic-button"
              data-testid="resend-two-factor-auth"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClickMenu}
              className="locked-help-link"
            >
              {t('こちら')}
            </button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {canUseSmsResend && (
                <MenuItem
                  data-testid="resend-sms"
                  onClick={() => {
                    handleClickSMS()
                    handleClose()
                  }}
                >
                  <ListItemIcon>
                    <Sms />
                  </ListItemIcon>
                  <ListItemText>{t('SMSを再送する')}</ListItemText>
                </MenuItem>
              )}
              {canUseEmailResend && (
                <MenuItem
                  data-testid="resend-email"
                  onClick={() => {
                    handleClickEmail()
                    handleClose()
                  }}
                >
                  <ListItemIcon>
                    <Mail />
                  </ListItemIcon>
                  <ListItemText>{t('メールを再送する')}</ListItemText>
                </MenuItem>
              )}
            </Menu>
          </p>
        )}
        <p className="text-sm mx-16 mt-16 mb-12">
          {t('二段階認証の完了後、自動でログインできない場合は')}
          <button
            data-test="submit"
            onClick={handleClick}
            className="locked-help-link"
          >
            {t('こちら')}
          </button>
        </p>
        <div className="mb-6 flex justify-center">
          <a
            className="locked-help-link"
            href="https://www.notion.so/de9fb681780c46588b0aec0c60057e21"
            target="_blank"
          >
            {t('よくある質問')}
          </a>
        </div>
      </div>
    </Layout>
  )
}
