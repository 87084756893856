import { GetResendSettingBySubdomainResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const getResendSettingBySubdomain = async (subdomain: string) => {
  const res = await axiosClient.get<GetResendSettingBySubdomainResponse>(
    `${fullUrl('api')}/team/resend_setting`,
    { params: { subdomain: subdomain } },
  )
  return res
}
